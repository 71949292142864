<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-cliente-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Listado Cliente</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="n_identificacion" class="col-md-5"
                      >Numero de identificación</label
                    >
                    <input
                      type="number"
                      id="n_identificacion"
                      v-model="filtros.n_identificacion"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="razon_social" class="col-md-5"
                      >Razon social</label
                    >
                    <input
                      type="text"
                      id="razon_social"
                      v-model="filtros.razon_social"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="linea_negocio_id" class="col-md-5"
                      >Linea de Negocio</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="linea_negocio"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.lineas_negocio"
                      :filterable="true"
                      @input="getSelectLineasNegocio()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="representante" class="col-md-5"
                      >Representante</label
                    >

                    <input
                      type="text"
                      id="representante"
                      v-model="filtros.representante"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="ciudad_id" class="col-md-5">Ciudad</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ciudad"
                      placeholder="Ciudades"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.ciudades"
                      :filterable="true"
                      @input="getSelectCiudades()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="email" class="col-md-5">Email</label>
                    <input
                      type="text"
                      id="email"
                      v-model="filtros.email"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="tipo_operacion" class="col-md-5">Estado</label>
                    <select
                      id="tipo_operacion"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="n_identificacion1"
                              v-model="form.n_identificacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="n_identificacion1"
                              >Numero de identificación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="razon_social1"
                              v-model="form.razon_social"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="razon_social1"
                              >Razon social</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="linea_negocio_id"
                              v-model="form.linea_negocio_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="linea_negocio_id"
                              >Linea de negocio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="representante1"
                              v-model="form.representante"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="representante1"
                              >Representante</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="ciudad_id"
                              v-model="form.ciudad_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="ciudad_id"
                              >Ciudad</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="telefono"
                              v-model="form.telefono"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="telefono"
                              >Telefono</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="cel1"
                              v-model="form.cel1"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="cel1"
                              >Celular 1</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="cel2"
                              v-model="form.cel2"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="cel2"
                              >Celular 2</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="email1"
                              v-model="form.email"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="email1"
                              >Email</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lista_distribucion"
                              v-model="form.lista_distribucion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lista_distribucion"
                              >Lista de Distribución</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lista_documentos"
                              v-model="form.lista_documentos"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lista_documentos"
                              >Lista de Documentos</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado_e"
                              v-model="form.estado_e"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado_e"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExce()"
                v-if="$store.getters.can('com.clientes.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "ClienteExport", //llegada tambien

  components: {
    Loading,
    vSelect,
  },

  data() {
    return {
      cargando: false,
      ciudad: {},
      linea_negocio: {},
      form: {
        n_identificacion: true,
        razon_social: true,
        linea_negocio_id: true,
        representante: true,
        ciudad_id: true,
        telefono: true,
        cel1: true,
        cel2: true,
        email: true,
        lista_distribucion: true,
        lista_documentos: true,
        estado_e: true,
      },
      filtros: {
        n_identificacion: null,
        razon_social: null,
        representante: null,
        lineas_negocio_id: null,
        ciudad_id: null,
        telefono: null,
        email: null,
      },
      listasForms: {
        lineas_negocio: [],
        ciudades: [],
        estados: [],
      },
    };
  },

  methods: {
    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getSelectCiudades() {
      this.filtros.ciudad_id = null;
      if (this.ciudad) {
        this.filtros.ciudad_id = this.ciudad.id;
      }
    },

    getSelectLineasNegocio() {
      this.filtros.linea_negocio_id = null;
      if (this.linea_negocio) {
        this.filtros.linea_negocio_id = this.linea_negocio.id;
      }
    },

    getCiudades() {
      axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    generarListadoExce() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/com/clientes/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          let data = response.data;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.getLineasNegocio();
    this.getCiudades();
    this.getEstados();
    this.cargando = false;
  },
};
</script>
